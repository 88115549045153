
import Vue from "vue";
import TextDivider from "@/components/common/TextDivider.vue";
import { Actions, ScansActions } from "@/store/models";
import { devices, IDevice, IScan, IScanFileType, scans, storage } from "@/api";
import DeviceCard from "@/components/ManageScans/DeviceCard.vue";
import ScanCard from "@/components/ManageScans/ManageScansScanCard.vue";
import NewScanDialog from "@/components/ManageScans/NewScanDialog.vue";
import NewScanFileDialog from "@/components/ManageScans/NewScanFileDialog.vue";

export default Vue.extend({
  name: "ManageScans",
  components: {
    TextDivider,
    DeviceCard,
    ScanCard,
    NewScanDialog,
    NewScanFileDialog,
  },
  data() {
    return {
      devices: [] as IDevice[],
      focusedDevice: {} as IDevice,
      scans: [] as IScan[],
      fileTypes: [] as IScanFileType[],
      storage: {
        used: "",
        total: "",
      },
      newScanDailog: false,
      newScanFileDailog: false,
      selectedScan: {} as IScan,
    };
  },
  computed: {
    selectedScans: function () {
      let ids: number[] = [];
      this.scans.forEach((scan: any) => {
        if (scan.selected) {
          ids.push(scan.id);
        }
      });
      return ids;
    },
  },
  created() {
    this.getDevices();
    this.getStorageInfo();
    this.getFileTypes();
  },
  methods: {
    async getFileTypes() {
      try {
        const fileTypes = await scans.fileType.get();
        this.fileTypes = fileTypes.data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async getStorageInfo() {
      try {
        const response = await storage.get();
        this.storage.used = response.data.storage_used;
        this.storage.total = response.data.storage_limit;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async getDevices() {
      try {
        const response = await devices.getAll();
        this.devices = response.data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async getScans(id: number) {
      this.focusedDevice = this.devices.find((device) => device.id === id)!;
      try {
        const response = await scans.getByDevice(id.toString());
        this.scans = response.data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    handleScanChange() {
      this.getScans(this.focusedDevice.id);
      this.getStorageInfo();
    },
    toggleNewScanDialog(dialog: boolean) {
      this.newScanDailog = dialog;
    },
    toggleNewScanFileDialog(dialog: boolean) {
      this.newScanFileDailog = dialog;
    },
    uploadScanFile(scan: IScan) {
      this.selectedScan = scan;
      this.toggleNewScanFileDialog(true);
    },
  },
});
