
import { ScansActions } from "@/store/models";
import { getDate, getTime } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "ManageScansScanCard",
  props: {
    scan: Object,
  },
  data() {
    return {};
  },
  methods: {
    lastUpdated() {
      const date = this.scan.updated_datetime || this.scan.created_datetime;
      return getDate(date) + " • " + getTime(date);
    },
    async deleteScan() {
      if (await this.$store.dispatch(ScansActions.Delete, [this.scan.id])) {
        this.$emit("handleScanChange");
      }
    },
  },
});
