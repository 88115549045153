
import { scans } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "NewScanDialog",
  props: {
    dialog: Boolean,
    fileTypes: Array,
    deviceId: Number,
  },
  data() {
    return {
      file: undefined,
      scanDescription: undefined,
      fileType: undefined,
    };
  },
  computed: {
    project: function() {
      const vm = this as any
      return vm.$store.state.company.projects[0] 
    },
    fileTypeNames: function () {
      const types = [] as string[];
      this.fileTypes.forEach((type: any) => {
        types.push(type.name);
      });
      return types;
    },
  },
  methods: {
    async uploadScan() {
      if (!this.scanDescription) {
        this.$store.dispatch(Actions.DisplayError, "Please fill in required details.");
        return;
      }
      try {
        const request = {
          device: {
            id: this.deviceId.toString(),
          },
          project: {
            id: this.project.id,
          },
          scan_description: this.scanDescription!,
        };
        const response = await scans.add(request as any); //changed
        const scanId = response.data.id;

        if (this.file && this.fileType) {
          await scans.details.add(scanId, this.file!, this.fileType!);
        }
        this.$emit("handleScanChange");
        this.$emit("toggleDialog", false);
        this.scanDescription = undefined
        this.file = undefined;
        this.fileType = undefined
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
