
import Vue from "vue";

export default Vue.extend({
  name: "TextDivider",
  props: {
    text: String
  },
  methods: {},
});
