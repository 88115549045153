
import { scans } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "NewScanFileDailog",
  props: {
    dialog: Boolean,
    fileTypes: Array,
    scan: Object,
  },
  data() {
    return {
      deviceId: this.$route.params.id,
      file: undefined,
      fileType: undefined,
    };
  },
  computed: {
    fileTypeNames: function () {
      const types = [] as string[];
      this.fileTypes.forEach((type: any) => {
        types.push(type.name);
      });
      return types;
    },
  },
  methods: {
    async uploadScan() {
      if (!this.file || !this.fileType) {
        this.$store.dispatch(Actions.DisplayError, "Please fill in required details.");
        return;
      }
      try {
        await scans.details.add(this.scan.id, this.file!, this.fileType!);

        this.$emit("handleScanChange");
        this.$emit("toggleDialog", false);
        this.file = undefined;
        this.fileType = undefined;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
