
import { IDevice } from "@/api";
import Vue from "vue";

export default Vue.extend({
  name: "DeviceCard",
  props: {
    device: Object,
    focusedDeviceId: Number,
  },
  data() {
    return {};
  },
  computed: {
    focused: function () {
      return this.focusedDeviceId === (this.device as IDevice).id;
    },
  },
  methods: {},
});
